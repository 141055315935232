<template>
    <v-layout column>
        <h4>Reported rooms</h4>
        <v-card class = "mt-2">
            <v-list v-if="!loading">
                <v-list-item v-for="(room, index) in items" :key = "`room${index}`">
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ room.title }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <small>ID: </small>{{ room.id }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-avatar>
                        <v-avatar>
                            <img :src = "room.user.image">
                        </v-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content class = "pl-4">
                        <v-list-item-title>
                            {{ room.user.username }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            Creator
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ room.count }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            Reports
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-content>
                        <v-list-item-title>
                            {{ room.status }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            Status
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-card-text v-else>
                <v-progress-circular indeterminate/>
            </v-card-text>
        </v-card>
        <div class="text-center" v-if = "pages > 0">
            <v-pagination
            v-model="page"
            :length="pages"
            ></v-pagination>
        </div>
    </v-layout>
</template>

<script>
    import axios from 'axios'
    import { URL } from '../../utils/consts'
    import { getUser } from '../../utils/login';
    export default {
        data() {
            return {
                total: 0,
                items: [],
                page: 1,
                numberOfItems: 10,
                loading: false,
                headers: [
                    {
                        text: 'Name of the room',
                        align: 'start',
                        sortable: true,
                        value: 'name',
                    },
                    { text: 'Number of reports', value: 'count' },
                    { text: 'State', value: 'state' },
                ],
            }
        },
        methods: {
            async fetch() {
                this.loading = true;
                const { data } = await axios.get(URL() + `rooms/admin/reports?limit=${this.numberOfItems}&offset=${(this.page - 1)*this.numberOfItems}`, {
                    headers: {
                    Authorization: "Bearer " + getUser().token,
                    }
                })
                this.total = data.total.length;
                this.items = data.items;
                this.loading = false;
            }
        },
        computed: {
            pages() {
                return Math.floor(this.total/this.numberOfItems)
            }
        },
        watch: {
            page() {
                this.fetch();
            }
        },
        mounted() {
            this.fetch();
        }
    }
</script>

<style lang="scss" scoped>

</style>